<template>
  <div style="overflow: clip; border-radius: 0.5rem" ref="tableContainer">
    <!-- table -->
    <vue-good-table
      v-bind="$attrs"
      v-on="$listeners"
      :columns="tableColumns"
      :rows="data"
      mode="remote"
      class="table-responsive-stack"
      :totalRows="meta.total"
      :isLoading.sync="state.isLoading"
      :search-options="{
        enabled: false,
        trigger: 'enter',
      }"
      :select-options="{
        enabled: selectable,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: params.perPage,
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: {
          field: params.sort.split(',')[0],
          type: params.sort.split(',')[1],
        },
      }"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-selected-rows-change="onSelectionChange"
      @on-search="refresh"
    >
      <template slot="table-row" slot-scope="props">
        <slot name="row" v-bind="props" />
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap mx-2">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t("component.datatable.per-page") }}
            </span>
            <b-form-select
              v-model="params.perPage"
              :options="params.pagination.options"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap pl-1">
              {{ $t("component.datatable.total-entries") }} {{ props.total }}
            </span>
          </div>
          <div>
            <b-pagination
              :value="params.page"
              :total-rows="props.total"
              :per-page="params.perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <div slot="emptystate" class="no-result">
        {{ $t("component.datatable.no-result") }}
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    fetchData: {
      type: Function,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    forceRefresh: {
      type: Boolean,
    },
  },
  computed: {
    tableColumns() {
      let width = Math.min(window.innerWidth - 56, 1440);
      let calculatedColumns = 0;
      this.columns.forEach((c) => {
        width -= c.width ?? 0;
        calculatedColumns += c.width ? 0 : 1;
      });
      const tempColumns = this.columns.filter((column) => {
        if (
          column.guard_name &&
          column.guard_name != this.$auth.user().guard_name
        ) {
          return false;
        }
        if (column.permission) {
          return this.$auth.check(column.permission);
        }
        return true;
      });
      const { page, perPage, sort, ...filters } = this.$route.query;
      let sortBy = null;
      if (sort && sort.split(",").length == 2) {
        sortBy = sort.split(",")[0];
      }
      const equalWidth = width / calculatedColumns;
      tempColumns.forEach((column) => {
        if (column.filterOptions && filters[column.field]) {
          column.filterOptions.filterValue = filters[column.field];
        }
        if (column.field == sortBy) {
          column.firstSortType = sort.split(",")[1];
        }

        // In some case first column takes all the space this is to prevent it from happening
        column.width = column.width ?? `${equalWidth}px`;
      });

      return tempColumns;
    },
  },
  data() {
    return {
      state: { isLoading: false, search: "" },
      data: [],
      meta: { total: 0 },
      rows: [],
      params: {
        filters: {},
        sort: "id,desc",
        page: 1,
        perPage: 50,
        pagination: {
          options: ['50', '100', '200']
        }
      },
    };
  },
  watch: {
    params: {
      deep: true,
      handler() {
        this.updateRoute();
        this.refresh();
      },
    },
    forceRefresh() {
      this.refresh();
    },
  },
  methods: {
    updateRoute() {
      history.replaceState(
        {},
        null,
        this.$route.path +
          "?" +
          new URLSearchParams(this.prepareFilterObject()).toString()
      );
    },
    updateParams(newProps) {
      this.params = Object.assign({}, this.params, newProps);
      this.params.page = newProps.page;
    },
    onPageChange(params) {
      this.params.page = params.currentPage;
    },
    onPerPageChange(params) {
      this.params.perPage = params.currentPerPage;
    },
    onSortChange(params) {
      if (params.length == 0) {
        this.params.sort = null;
      } else {
        this.params.sort = `${params[0].field},${params[0].type}`;
      }
    },
    onColumnFilter(params) {
      const filters = {};
      Object.keys(params.columnFilters)
        .filter((f) => !!params.columnFilters[f])
        .forEach((f) => (filters[f] = params.columnFilters[f]));
      this.params.filters = filters;
    },
    onSelectionChange({ selectedRows }) {
      this.$emit("on-selection-changed", selectedRows);
    },
    prepareFilterObject() {
      return {
        ...this.params.filters,
        sort: this.params.sort,
        page: this.params.page,
        perPage: this.params.perPage,
      };
    },
    parseFilterObject() {
      const { page, perPage, sort, ...filters } = this.$route.query;
      this.params.page = page ?? 1;
      this.params.perPage = this.params.pagination.options.includes(perPage) ? perPage : this.params.pagination.options[0];
      this.params.sort = sort ?? "id,desc";
      this.params.filters = filters;
    },
    async refresh() {
      this.state.isLoading = true;
      try {
        const {
          data: { data, meta },
        } = await this.fetchData(this.prepareFilterObject());
        this.data = data;
        this.meta.total = meta.total;
      } catch (e) {
      } finally {
        this.state.isLoading = false;
      }
    },
  },
  mounted() {
    this.parseFilterObject();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

table.vgt-table td {
  vertical-align: middle !important;

  .tag-box .badge {
    color: white !important;
  }
}

.dark-layout .vgt-select {
  background-color: #283046 !important;
  border-color: #3b4253;
  color: #b4b7bd;
}

.no-result {
  height: 5rem;
  display: grid;
  place-items: center;
}
</style>
