<template>
  <b-button
    :variant="variant"
    class="btn-icon rounded-circle"
    :title="title"
    v-ripple
    :to="to"
    @click="$emit('click')"
    v-if="$auth.check(permissions)"
  >
    <feather-icon :icon="icon" />
    <span class="sr-only">{{ title }}</span>
  </b-button>
</template>

<script>
export default {
  name: "ActionButton",
  props: {
    variant: {
      type: String,
      default: "flat-success text-success",
    },
    icon: {
      type: String,
      default: "Edit2Icon",
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      required: false,
    },
    permissions: {
      type: Array | String,
      default: null,
    },
  },
};
</script>

<style>
</style>